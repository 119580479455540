<template>
    <div class="w-100">
        <div class="p-3">
            <h1 style="opacity: 0.7; font-size: 1.25rem;" class="generic-typography">
                {{ $gettext("Change_your_password") }}
            </h1>
        </div>
        <div>
            <b-form class="customized-form w-100">
                <ValidationObserver ref="changePassword" tag="div">
                    <div class="col-md-6">
                        <ValidationProvider rules="required" name="oldPassword" v-slot="{ errors, classes }">
                            <b-form-group class="zc-form-label" :label='$gettext(" Old_Password")' label-for="oldPassword">
                                <b-form-input :class="classes" id="oldPassword" type="password" v-model="oldPassword"
                                    size="sm" :placeholder='$gettext(" Input_your_old_password")'></b-form-input>
                                <img @click="viewPassword" style="position: absolute;top: 42px;right: 27px;"
                                    src="@/assets/zaajira-candidate/assets/icons/eye.svg" alt="" srcset="">
                                <img @click="viewPassword" style="display:none;position: absolute;top: 42px;right: 27px;"
                                    src="@/assets/zaajira-candidate/assets/icons/line.svg" alt="" srcset="">
                            </b-form-group>
                            <!-- <PasswordInput :ValidatinClasses="classes" v-model="oldPassword" classes="zc-form-label"
                                label="Old Password" labelFor="oldPassword" inputId="myPasswordInput" inputSize="sm"
                                inputPlaceholder="Input your old password" /> -->
                            <div class="error-msg" :class="classes">
                                <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider rules="required|max:15|min:8" name="newPassword" vid="confirmation"
                            v-slot="{ errors, classes }">
                            <!-- <PasswordInput :ValidatinClasses="classes" v-model="newPassword" classes="zc-form-label"
                                label="New Password" labelFor="newPassword" inputId="newPassword" inputSize="sm"
                                inputPlaceholder="Input your New Password" /> -->
                            <b-form-group class="zc-form-label" :label='$gettext(" New_Password")' label-for="newPassword">
                                <b-form-input :class="classes" id="newPassword" type="password" v-model="newPassword"
                                    size="sm" :placeholder='$gettext("Input_your_New_Password")'></b-form-input>
                                <img @click="viewPassword" style="position: absolute;top: 42px;right: 27px;"
                                    src="@/assets/zaajira-candidate/assets/icons/eye.svg" alt="" srcset="">
                                <img @click="viewPassword" style="display:none;position: absolute;top: 42px;right: 27px;"
                                    src="@/assets/zaajira-candidate/assets/icons/line.svg" alt="" srcset="">
                            </b-form-group>
                            <div class="error-msg" :class="classes">
                                <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider rules="required|confirmed:confirmation" name="confirmPassword"
                            v-slot="{ errors, classes }">
                            <b-form-group class="zc-form-label" :label='$gettext(" Retype_New_Password")'
                                label-for="confirmPassword">
                                <b-form-input :class="classes" id="confirmPassword" type="password"
                                    v-model="confirmPassword" size="sm"
                                    :placeholder='$gettext("Retype_New_Password")'></b-form-input>
                                <img @click="viewPassword" style="position: absolute;top: 42px;right: 27px;"
                                    src="@/assets/zaajira-candidate/assets/icons/eye.svg" alt="" srcset="">
                                <img @click="viewPassword" style="display:none;position: absolute;top: 42px;right: 27px;"
                                    src="@/assets/zaajira-candidate/assets/icons/line.svg" alt="" srcset="">
                            </b-form-group>
                            <!-- <PasswordInput :ValidatinClasses="classes" v-model="confirmPassword" classes="zc-form-label"
                                label="Retype New Password" labelFor="confirmPassword" inputId="confirmPassword"
                                inputSize="sm" inputPlaceholder="Input again your new password" /> -->

                            <div class="error-msg" :class="classes">
                                <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 mb-2 btn-flex-fd-row-jc-sb">
                        <!-- <span @click="goBack" class="go-back">Back</span> -->
                        <b-button @click.prevent.stop="ValidateInput" variant="primary" size="sm">{{ $gettext("Save_change")
                        }}</b-button>

                    </div>
                </ValidationObserver>
            </b-form>
        </div>
    </div>
</template>

<script>
import PasswordInput from '../../../components/Password/Password-Input.vue';
import CandidateService from "@/apiServices/CandidateService";

export default {
    name: "changePassword",
    components: { PasswordInput },
    data() {
        return {
            oldPassword: null,
            newPassword: null,
            confirmPassword: null,
            passwordType: true,
            mobile: '',
            email: '',
        }
    },

    methods: {
        viewPassword(e) {
            // console.log(e.target.parentNode);
            const inputField = e.target.parentNode.firstChild
            const CrossBTN = e.target.parentNode.childNodes[2]
            // console.log(CrossBTN);
            if (inputField.type === 'password') {
                inputField.type = 'text'
                CrossBTN.style = 'position: absolute; top: 42px; right: 27px;'
            }
            else {
                inputField.type = 'password'
                CrossBTN.style = 'display:none;'
            }
            this.passwordType = !this.passwordType
        },
        ValidateInput() {
            return this.$refs.changePassword.validate().then((result) => {
                if (result) {

                    if (this.email === '') {
                        this.$toasted.show(`Fill email to change password!`, { duration: 6000 });
                        setTimeout(() => {
                            this.$router.push({ path: '/candidate/personal-details' })
                        }, 1000)
                    }
                    else {
                        const payload = {
                            email: this.email,
                            password: this.oldPassword,
                            mobile_number: '',
                            role_id: 3,

                        }
                        // console.log(payload);
                        let loader = this.$loading.show();
                        CandidateService.Login(payload)
                            .then(async (res) => {
                                // console.log("Login With Email::", res);
                                if (res.data.data.notFound) {
                                    this.$toasted.show(`${res.data.data.msg}`, { duration: 6000 });
                                    loader.hide();
                                    return
                                } else {
                                    if (res.data.data.invalidPass) {
                                        this.$toasted.show(`Old password incorrect!`, { duration: 6000 });
                                        loader.hide();
                                        return
                                    }
                                    else {
                                        // console.log("Login::", res);                                    

                                        if (res.data.message === "Login successful") {
                                            const payload = {
                                                "password": this.newPassword
                                            }
                                            // console.log(payload);

                                            CandidateService.updateUser(payload)
                                                .then((res) => {
                                                    // console.log(res);
                                                    // console.log('res', res.data.data);
                                                    // console.log('res', res.data.message);
                                                    if (res.data.message === "Success") {
                                                        this.$toasted.show(`Password changed successfully!`, {
                                                            duration: 6000,
                                                            type: "success",
                                                            icon: "check",
                                                        });
                                                    }
                                                    // this.$router.push({ name: "Industry" })
                                                    loader.hide();
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    loader.hide();
                                                })
                                        }
                                        loader.hide();

                                    }
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                    }


                }
            })
        }
    },
    beforeMount() {
        const userData = this.$store.getters.getLoggedUser.userData
        this.email = this.$store.getters.getLoggedUser.userData.user_email
        this.mobile = this.$store.getters.getLoggedUser.userData.user_mobile_number
    }


}
</script>

<style></style>